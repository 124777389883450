export default function initMultiSwitchers() {
	var sections = document.querySelectorAll("[data-switcher]");
	var triggers = document.querySelectorAll("[data-switcher-trigger]");
	if (sections.length > 0 && triggers.length > 0) {
		multiSwitcher(triggers, sections);
	}
}

/*
	Takes triggers and targets - both which require data-type attributes.
	When a $trigger is clicked it adds/removes 'is-active' class to each $target if
	it's type matches the clicked $trigger type.
 */

function multiSwitcher(triggers, targets) {
	const urlParams = new URLSearchParams(window.location.search);
	const selectedType = urlParams.get("switcher");

	triggers.forEach(trigger => {
		const newType = trigger.dataset.type;
		trigger.addEventListener("click", e => {
			select(triggers, trigger, targets, newType);

			e.preventDefault();
		});

		if (selectedType === newType) {
			select(triggers, trigger, targets, selectedType);
		}
	});
}

function select(triggers, currentTrigger, targets, switcherType) {
	targets.forEach(target => {
		if (target.dataset.type && target.dataset.type === switcherType) {
			target.classList.add("is-active");
		} else {
			target.classList.remove("is-active");
		}
	});

	triggers.forEach(trigger => {
		trigger.classList.remove("is-active");
	});
	currentTrigger.classList.add("is-active");
}
