module.exports = {
	getRandomInRange: getRandomInRange,
	getAnchorTarget: getAnchorTarget,
	documentReady: documentReady,
	addClass: addClass,
	hasClass: hasClass,
	removeClass: removeClass,
	toggleClass: toggleClass,
	addEventListener: addEventListener,
	getUrl: getUrl,
	cloneCanvas: cloneCanvas,
	drawImageProp: drawImageProp,
	requestInterval: requestInterval,
	requestTimeout: requestTimeout,
	jsonp: jsonp,
	serialize: serialize,
	closest: closest,
	gEvent: gEvent,
	sendAnalytics: sendAnalytics,
	stringDoesNotContain: stringDoesNotContain,
	imageCanvas: imageCanvas,
	debounce: debounce
};

function debounce(func, time) {
	var time = time || 100; // 100 by default if no param
	var timer;
	return function(event) {
		if (timer) clearTimeout(timer);
		timer = setTimeout(func, time, event);
	};
}

function imageCanvas(src) {
	return new Promise((resolve, reject) => {
		const canvas = document.createElement("canvas");

		const img = new Image();
		img.crossOrigin = "anonymous";

		img.onload = function() {
			canvas.width = img.width;
			canvas.height = img.height;
			const ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
			resolve(canvas);
		};

		img.src = src;
	});
}

function sendAnalytics() {
	if (window.gtag !== undefined) {
		gtag("event", "page_view", {
			page_title: document.title
		});
	}
	if (window.fbq !== undefined) {
		fbq("track", "PageView");
	}
}

function gEvent(category, action, label = "") {
	if (window.ga) {
		ga("tf.send", {
			hitType: "event",
			eventCategory: category,
			eventAction: action,
			eventLabel: label
		});
	}
}

function stringDoesNotContain(string, needles) {
	for (var i = 0; i <= needles.length - 1; i++) {
		if (string.indexOf(needles[i]) !== -1) {
			return true;
		}
	}
	return false;
}

function closest(arr, closestTo) {
	arr = arr.map(function(x) {
		return parseInt(x, 10);
	});

	var closest = Math.max.apply(null, arr);

	for (var i = 0; i < arr.length; i++) {
		if (arr[i] >= closestTo && arr[i] < closest) closest = arr[i];
	}

	return closest;
}

function requestTimeout(fn, delay) {
	if (
		!window.requestAnimationFrame &&
		!window.webkitRequestAnimationFrame &&
		!(
			window.mozRequestAnimationFrame && window.mozCancelRequestAnimationFrame
		) && // Firefox 5 ships without cancel support
		!window.oRequestAnimationFrame &&
		!window.msRequestAnimationFrame
	)
		return window.setTimeout(fn, delay);

	var start = new Date().getTime(),
		handle = new Object();

	function loop() {
		var current = new Date().getTime(),
			delta = current - start;

		delta >= delay ? fn.call() : (handle.value = requestAnimationFrame(loop));
	}

	handle.value = requestAnimationFrame(loop);
	return handle;
}

function requestInterval(fn, delay) {
	if (
		!window.requestAnimationFrame &&
		!window.webkitRequestAnimationFrame &&
		!(
			window.mozRequestAnimationFrame && window.mozCancelRequestAnimationFrame
		) && // Firefox 5 ships without cancel support
		!window.oRequestAnimationFrame &&
		!window.msRequestAnimationFrame
	)
		return window.setInterval(fn, delay);

	var start = new Date().getTime(),
		handle = new Object();

	function loop() {
		var current = new Date().getTime(),
			delta = current - start;

		if (delta >= delay) {
			fn.call();
			start = new Date().getTime();
		}

		handle.value = requestAnimationFrame(loop);
	}

	handle.value = requestAnimationFrame(loop);
	return handle;
}

function getRandomInRange(from, to) {
	return (Math.random() * (to - from) + from).toFixed(0) * 1;
}

function getAnchorTarget(link) {
	var id = link.hash.replace("#", "");
	return document.getElementById(id) || null;
}

function addClass(element, className) {
	if (element instanceof SVGElement) {
		element.setAttribute(
			"class",
			element.getAttribute("class") + " " + className
		);
	} else {
		if (element.classList) {
			element.classList.add(className);
		} else {
			element.className += " " + className;
		}
	}
}

/**
 * By Ken Fyrstenberg Nilsen
 *
 * drawImageProp(context, image [, x, y, width, height [,offsetX, offsetY]])
 *
 * If image and context are only arguments rectangle will equal canvas
 */
function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {
	if (arguments.length === 2) {
		x = y = 0;
		w = ctx.canvas.width;
		h = ctx.canvas.height;
	}

	// default offset is center
	offsetX = typeof offsetX === "number" ? offsetX : 0.5;
	offsetY = typeof offsetY === "number" ? offsetY : 0.5;

	// keep bounds [0.0, 1.0]
	if (offsetX < 0) offsetX = 0;
	if (offsetY < 0) offsetY = 0;
	if (offsetX > 1) offsetX = 1;
	if (offsetY > 1) offsetY = 1;

	var iw = img.width,
		ih = img.height,
		r = Math.min(w / iw, h / ih),
		nw = iw * r, // new prop. width
		nh = ih * r, // new prop. height
		cx,
		cy,
		cw,
		ch,
		ar = 1;

	// decide which gap to fill
	if (nw < w) ar = w / nw;
	if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh; // updated
	nw *= ar;
	nh *= ar;

	// calc source rectangle
	cw = iw / (nw / w);
	ch = ih / (nh / h);

	cx = (iw - cw) * offsetX;
	cy = (ih - ch) * offsetY;

	// make sure source rectangle is valid
	if (cx < 0) cx = 0;
	if (cy < 0) cy = 0;
	if (cw > iw) cw = iw;
	if (ch > ih) ch = ih;

	// fill image in dest. rectangle
	ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
}

function cloneCanvas(sourceCanvas) {
	var destinationCanvas = document.createElement("canvas");
	destinationCanvas.width = sourceCanvas.width;
	destinationCanvas.height = sourceCanvas.height;

	var destCtx = destinationCanvas.getContext("2d");

	destCtx.drawImage(sourceCanvas, 0, 0);

	return destinationCanvas;
}

function removeClass(element, className) {
	if (element instanceof SVGElement) {
		var updatedClassListString = element
			.getAttribute("class")
			.replace(new RegExp("(\\s|^)" + className + "(\\s|$)", "g"), "$2");
		element.setAttribute("class", updatedClassListString);
	} else {
		if (element.classList) {
			element.classList.remove(className);
		} else {
			var classes = element.className.split(" ");
			var existingIndex = classes.indexOf(className);

			if (existingIndex >= 0) {
				classes.splice(existingIndex, 1);
			}

			element.className = classes.join(" ");
		}
	}
}

function hasClass(element, className) {
	if (element instanceof SVGElement) {
		return new RegExp("(\\s|^)" + className + "(\\s|$)").test(
			element.getAttribute("class")
		);
	} else {
		if (element.classList) {
			return element.classList.contains(className);
		} else {
			return element.className.indexOf(className) !== -1;
		}
	}
}

function toggleClass(element, className) {
	if (element.classList) {
		element.classList.toggle(className);
	} else {
		var classes = element.className.split(" ");
		var existingIndex = classes.indexOf(className);

		if (existingIndex >= 0) {
			classes.splice(existingIndex, 1);
		} else {
			classes.push(className);
		}

		element.className = classes.join(" ");
	}
}

function addEventListener(el, eventName, handler) {
	if (el.addEventListener) {
		el.addEventListener(eventName, handler);
	} else {
		el.attachEvent("on" + eventName, function() {
			handler.call(el);
		});
	}
}

function getUrl(path) {
	if (!window.location.origin) {
		window.location.origin =
			window.location.protocol +
			"//" +
			window.location.hostname +
			(window.location.port ? ":" + window.location.port : "");
	}

	if (path.indexOf("./") === 0) {
		path = path.slice(1); // Remove the .
		var existingPath = window.location.pathname || "";
		return window.location.origin + existingPath + path;
	} else if (path) {
		return window.location.origin + path;
	} else {
		return window.location.origin + window.location.pathname;
	}
}

function documentReady(fn) {
	if (document.readyState != "loading") {
		fn();
	} else if (document.addEventListener) {
		document.addEventListener("DOMContentLoaded", fn);
	} else {
		document.attachEvent("onreadystatechange", function() {
			if (document.readyState != "loading") fn();
		});
	}
}

function serialize(form) {
	var field,
		l,
		s = [];
	if (typeof form == "object" && form.nodeName == "FORM") {
		var len = form.elements.length;
		for (var i = 0; i < len; i++) {
			field = form.elements[i];
			if (
				field.name &&
				!field.disabled &&
				field.type != "file" &&
				field.type != "reset" &&
				field.type != "submit" &&
				field.type != "button"
			) {
				if (field.type == "select-multiple") {
					l = form.elements[i].options.length;
					for (var j = 0; j < l; j++) {
						if (field.options[j].selected)
							s[s.length] =
								encodeURIComponent(field.name) +
								"=" +
								encodeURIComponent(field.options[j].value);
					}
				} else if (
					(field.type != "checkbox" && field.type != "radio") ||
					field.checked
				) {
					s[s.length] =
						encodeURIComponent(field.name) +
						"=" +
						encodeURIComponent(field.value);
				}
			}
		}
	}
	return s.join("&").replace(/%20/g, "+");
}

function jsonp(src, options) {
	var options = options || {},
		callback_name = options.callbackName || "callback",
		on_success = options.onSuccess || function() {},
		on_timeout = options.onTimeout || function() {},
		timeout = options.timeout || 10;

	var timeout_trigger = window.setTimeout(function() {
		window[callback_name] = function() {};
		on_timeout();
	}, timeout * 1000);

	window[callback_name] = function(data) {
		window.clearTimeout(timeout_trigger);
		on_success(data);
	};

	var script = document.createElement("script");
	script.type = "text/javascript";
	script.async = true;
	script.src = src;

	document.getElementsByTagName("head")[0].appendChild(script);
}
