import "../scss/fest21.scss";

// SVGs to make into sprite
var __svg__ = {
	path: "../svgs/sprite/*.svg",
	name: "../../theunconformity/jinja2/svgs/sprite.svg"
};

// Polyfill all the things...
import "whatwg-fetch";
// For anything not-DOM (eg String.repeat, Promise, Array.fill etc) use core-js.
// See available options here -> https://github.com/zloirock/core-js#features
import "core-js/fn/array/from"; // Array.from()
import "core-js/fn/array/find"; // Array.find()
import "core-js/es6/promise"; // Promise()

import "./includes/polyfills";

import u from "./includes/utils";
import initTransitions from "./includes/transitions/index";
import initMultiSwitchers from "./includes/multiswitcher";

import menuActions from "./includes/menuActions";
import choiceOther from "./includes/choiceOther";
import favourites from "./includes/favourites";
import dismissable from "./includes/dismissable";
import videoEmbed from "./includes/videoEmbed";
import acknowledgementOverlay from "./includes/acknowledgementOverlay";
import initAccordions from "./includes/accordion";
import initTito from "./includes/titoTickets";
import { Fest21Graphic } from "./includes/fest21Graphic";
import emailSubscribeForms from "./includes/emailSubscribeForms";

window.addEventListener("orientationchange", _ => {
	// This is useful to stop tranisitons from running as the orientation changes (eg the mobile menu)
	document.body.classList.add("orientation-changing");
	u.requestTimeout(() => {
		document.body.classList.remove("orientation-changing");
	}, 400);
});

// This *must* be called early and before DOMReady/onload so the site
// doesnt display a flash of the overlay unnecesarily -JS
acknowledgementOverlay();

u.documentReady(() => {
	initTransitions(onFinish, onFinishAfterTimeout);

	onFinish();
	onFinishAfterTimeout();

	document.body.classList.add("page-has-loaded");
});

function onFinishAfterTimeout() {
	initTito();
	menuActions();
}

function onFinish() {
	favourites();
	dismissable();
	initMultiSwitchers();
	videoEmbed();
	choiceOther();
	emailSubscribeForms();
	initAccordions(true);

	let $containers = document.querySelectorAll("[data-fest21-gfx]");
	$containers.forEach(
		$c => new Fest21Graphic($c, _ => $c.parentNode.classList.add("gfx-loaded"))
	);
}
