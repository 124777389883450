export default function acknowledgementOverlay() {
	const $acknowledgement = document.querySelector("[data-acknowledgement]");
	const $body = document.querySelector("body");
	const hasUserSeenAcknowledgement = localStorage.getItem("UNCOack");

	if ($acknowledgement && hasUserSeenAcknowledgement !== "true") {
		const $closeAcknowledgement = document.querySelector(
			"[data-acknowledgement-close]"
		);

		$body.classList.add("animate-overlay-setup");

		window.setTimeout(_ => {
			$body.classList.add("animate-overlay-in");
		}, 50);

		const timerId = window.setTimeout(_ => {
			$body.classList.add("animate-overlay-out");
		}, 8000);

		localStorage.setItem("UNCOack", true);

		$closeAcknowledgement.addEventListener("click", e => {
			$body.classList.add("animate-overlay-out");
			window.clearTimeout(timerId);
			e.preventDefault();
		});
	} else {
		$body.classList.add("animate-overlay-out");
	}
}
