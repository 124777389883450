import { gsap } from "gsap";

class Fest21Graphic {
	constructor(container, onGfxLoaded) {
		this.container = container;
		this.stageCan = document.createElement("canvas");
		this.stageCtx = this.stageCan.getContext("2d");
		this.onGfxLoaded = onGfxLoaded;

		this.animationProps = { prog: 0 };
		this.srcImage = null;
		this.qWidth = null; // Width of one quarter segment
		this.qHeight = null; // Height of one quarter segment
		this.corners = [];
		this.prefersReducedMotion = window.matchMedia(
			"(prefers-reduced-motion: reduce)"
		).matches;

		this.container.appendChild(this.stageCan);

		this.setup();
	}

	async setup() {
		this.srcImage = await this.fetchImage();
		this.setSize();
		this.createContexts();
		this.play();
	}

	async fetchImage(src) {
		const sizes = [
			[450, "(max-width: 449px)"],
			[768, "(min-width: 450px) and (max-width: 767px)"],
			[1280, "(min-width: 768px) and (max-width: 1279px)"],
			[1440, "(min-width: 1280px)"]
		];
		const checkSize = s => window.matchMedia(s[1]).matches;
		const size = sizes.find(s => checkSize(s))[0];
		const imgUrl = this.container.dataset[`fest21GfxAsset-${size}`];

		return new Promise((resolve, reject) => {
			let img = new Image();
			img.onload = () => resolve(img);
			img.onerror = reject;
			img.src = imgUrl;
		});
	}

	setSize() {
		this.stageCan.width = this.srcImage.width * 2;
		this.stageCan.height = this.srcImage.height * 2;
		this.qWidth = this.srcImage.width;
		this.qHeight = this.srcImage.height;
	}

	createContexts() {
		for (let i = 0; i <= 3; i++) {
			let c = document.createElement("canvas");
			c.width = this.qWidth;
			c.height = this.qHeight;
			this.corners.push({
				canvas: c,
				ctx: c.getContext("2d")
			});
		}
	}

	play() {
		this.render();
		this.onGfxLoaded();

		if (!this.prefersReducedMotion) {
			gsap.to(this.animationProps, {
				duration: 60 * 5,
				repeat: -1,
				yoyo: true,
				ease: "none",
				prog: 0.15,
				onUpdate: this.render.bind(this)
			});
		}
	}

	render() {
		const prog = this.animationProps.prog;
		const progW = prog * this.qWidth;
		const progH = prog * this.qHeight;
		this.stageCtx.clearRect(0, 0, this.stageCan.width, this.stageCan.height);

		// Top left
		this.corners[0].ctx.clearRect(0, 0, this.qWidth, this.qHeight);
		this.corners[0].ctx.drawImage(this.srcImage, progW, progH);
		this.stageCtx.drawImage(this.corners[0].canvas, 0, 0);

		// Top right
		this.corners[1].ctx.clearRect(0, 0, this.qWidth, this.qHeight);
		this.corners[1].ctx.save();
		this.corners[1].ctx.translate(this.qWidth, 0);
		this.corners[1].ctx.scale(-1, 1);
		this.corners[1].ctx.drawImage(this.srcImage, progW, progH);
		this.corners[1].ctx.restore();
		this.stageCtx.drawImage(this.corners[1].canvas, this.qWidth, 0);

		// Bottom left
		this.corners[2].ctx.clearRect(0, 0, this.qWidth, this.qHeight);
		this.corners[2].ctx.save();
		this.corners[2].ctx.translate(0, this.qHeight);
		this.corners[2].ctx.scale(1, -1);
		this.corners[2].ctx.drawImage(this.srcImage, progW, progH);
		this.corners[2].ctx.restore();

		this.stageCtx.drawImage(this.corners[2].canvas, 0, this.qHeight);

		// Bottom right
		this.corners[3].ctx.clearRect(0, 0, this.qWidth, this.qHeight);
		this.corners[3].ctx.save();
		this.corners[3].ctx.translate(this.qWidth, this.qHeight);
		this.corners[3].ctx.rotate((180 * Math.PI) / 180);
		this.corners[3].ctx.drawImage(this.srcImage, progW, progH);
		this.corners[3].ctx.restore();

		this.stageCtx.drawImage(this.corners[3].canvas, this.qWidth, this.qHeight);
	}
}

export { Fest21Graphic };
