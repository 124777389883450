import Barba from "barba.js";
import u from "../utils";

function createBarba(onFinish, onFinishAfterTimeout) {
	const BasicTransition = Barba.BaseTransition.extend({
		start: function() {
			Promise.all([this.newContainerLoading, this.wait()]).then(
				this.finish.bind(this)
			);
		},

		wait: function() {
			u.addClass(document.body, "is-transitioning");

			return new Promise(function(resolve, reject) {
				u.requestTimeout(() => {
					resolve();
				}, 1000); // We want our transition to take 800ms -JS
			});
		},
		finish: function() {
			onFinish();
			u.requestTimeout(() => {
				// We have to wait for doPageInitCommon to be done before we can display the new page
				// @todo: Find a less hacky way of doing this!
				this.done();
				onFinishAfterTimeout();
				u.removeClass(document.body, "is-transitioning");
				u.removeClass(document.body, "has-video-playing"); // In case a user navigates whilst watching a video -JS
				window.scroll({
					top: 0,
					left: 0,
					behavior: "smooth"
				});
			}, 20);
		}
	});

	Barba.Pjax.getTransition = function() {
		return BasicTransition;
	};

	Barba.Pjax.ogPreventCheck = Barba.Pjax.preventCheck;

	Barba.Pjax.preventCheck = function(evt, element) {
		if (!Barba.Pjax.ogPreventCheck(evt, element)) {
			return false;
		}

		const fileExtensions = [
			".pdf",
			".doc",
			".docx",
			".xls",
			".xlsx",
			".zip",
			".tar.gz",
			".csv",
			".mov",
			".mp4",
			".mp3"
		];

		const link = element.href.toLowerCase();

		if (element.href && u.stringDoesNotContain(link, fileExtensions)) {
			return false;
		}

		return true;
	};

	return Barba;
}

export default createBarba;
