export default function videoEmbed() {
	const $embeds = document.querySelectorAll("[data-video-embed]");

	$embeds.forEach($embed => {
		let $trigger = $embed.querySelector("[data-video-embed-trigger]");
		$trigger.addEventListener("click", e => {
			$embed.insertAdjacentHTML("beforeEnd", $embed.dataset.videoEmbed);
			$embed.classList.add("has-video");
			document.body.classList.add("has-video-playing");
			e.preventDefault();
		});
	});
}
