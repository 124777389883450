import qs from "qs";
import notify from "./notify";
import u from "./utils";

export default function initFavourites() {
	new Favourites();
}

const UNCO_PAGESTORE_KEY = "UNCOPageStore";
const BASE_URL = window.location.origin + window.location.pathname;

const arraysAreEqual = (a, b) => {
	if (a.length !== b.length) {
		return false;
	}
	const aSorted = a.sort();
	const bSorted = b.sort();

	return aSorted.map((val, i) => bSorted[i] === val).every(isSame => isSame);
};

class Favourites {
	constructor(height, width) {
		this.eventCards = document.querySelectorAll("[data-event]");
		this.$shareUrlInput = document.querySelector("[data-share-url]");
		this.$shareUrlform = document.querySelector("[data-share-form]");
		this.$consentModal = document.querySelector("[data-consent-modal]");

		if (this.$shareUrlInput) {
			this.$shareUrlInput.value = BASE_URL;
		}

		const $addFavouriteTriggers = document.querySelectorAll(
			"[data-favourite-trigger]"
		);

		$addFavouriteTriggers.forEach($trigger => {
			let eventTimeId = $trigger.dataset.event;

			$trigger.addEventListener("click", e => {
				this.toggle(eventTimeId);
				e.preventDefault();
			});
		});

		const $externalAddFavouriteTriggers = document.querySelectorAll(
			"[data-favourite-trigger-external]"
		);

		$externalAddFavouriteTriggers.forEach($trigger => {
			let eventTimeId = $trigger.dataset.event;
			if (this.exists(eventTimeId)) {
				$trigger.classList.add("is-active");
			}

			$trigger.addEventListener("click", e => {
				this.toggle(eventTimeId);
				if (this.exists(eventTimeId)) {
					$trigger.classList.add("is-active");
				} else {
					$trigger.classList.remove("is-active");
				}
				e.preventDefault();
			});
		});

		const $filterFavouritesTrigger = document.querySelector(
			"[data-toggle-favourites]"
		);

		if ($filterFavouritesTrigger) {
			$filterFavouritesTrigger.addEventListener("change", e => {
				this.filterFavouritesHTML(this.getPages());
				u.gEvent(
					"Program",
					"filter_favourite",
					$filterFavouritesTrigger.checked ? "Show favourites" : "Show all"
				);
			});
		}

		const $shareFormTrigger = document.querySelector("[data-open-share]");

		if ($shareFormTrigger) {
			$shareFormTrigger.addEventListener("click", e => {
				this.openModal(this.$shareUrlform);
				u.gEvent("Program Share", "open_modal");
				e.preventDefault();
			});

			this.$shareUrlform.addEventListener("submit", e => {
				this.submitShare();
				u.gEvent("Program Share", "submit");
				e.preventDefault();
			});

			this.$shareUrlform
				.querySelector("[data-close-modal]")
				.addEventListener("click", e => {
					this.closeModal(this.$shareUrlform);
					u.gEvent("Program Share", "close_modal");
					e.preventDefault();
				});

			this.updateShareUrl();
		}

		if (this.eventCards.length > 0) {
			const sharedFavs = this.getFavouritesFromURL();
			const currentFavs = this.getPages().favourites;
			if (
				sharedFavs &&
				currentFavs.length > 0 &&
				!arraysAreEqual(sharedFavs, currentFavs)
			) {
				this.replaceFavouritesOnConsent(sharedFavs);
				u.gEvent(
					"Program Share",
					"open_link_conflicts",
					`sf:${sharedFavs.length}`
				);
			} else if (sharedFavs) {
				this.replaceFavourites(sharedFavs);
				u.gEvent("Program Share", "open_link", `sf:${sharedFavs.length}`);
				this.clearURL();
			}

			this.updateFavouritesHTML();
		}
	}

	// TODO: Modal could probably be it's own external class - and be used for the Map maybe -JS
	openModal($modal) {
		$modal.classList.add("is-active");
		document.body.classList.add("modal-is-open");
	}

	closeModal($modal) {
		$modal.classList.remove("is-active");
		document.body.classList.remove("modal-is-open");
	}

	replaceFavouritesOnConsent(newFavourites) {
		this.openModal(this.$consentModal);
		const $yes = this.$consentModal.querySelector("[data-consent-yes]");
		const $no = this.$consentModal.querySelector("[data-consent-no]");

		$yes.addEventListener(
			"click",
			function _yesHandler(e) {
				this.replaceFavourites(newFavourites);
				this.closeModal(this.$consentModal);
				this.clearURL();
				$yes.removeEventListener("click", _yesHandler, true);
				u.gEvent("Program Share", "overwrite_yes");
				e.preventDefault();
			}.bind(this),
			true
		);

		$no.addEventListener(
			"click",
			function _noHandler(e) {
				this.closeModal(this.$consentModal);
				this.clearURL();
				$no.removeEventListener("click", _noHandler, true);
				u.gEvent("Program Share", "overwrite_no");
				e.preventDefault();
			}.bind(this),
			true
		);
	}

	replaceFavourites(newFavourites) {
		let storeData = this.getPages();
		storeData.favourites = newFavourites;
		this.updateStore(storeData);
		this.updateFavouritesHTML();
		notify("Your saved program has been loaded.");
	}

	clearURL() {
		window.history.replaceState({}, document.title, BASE_URL);
	}

	getFavouritesFromURL() {
		const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		return query.saved ? query.saved.split("|") : false;
	}

	submitShare() {
		fetch(this.$shareUrlform.action, {
			method: this.$shareUrlform.method,
			credentials: "same-origin",
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			},
			body: new FormData(this.$shareUrlform)
		})
			.then(response => response.json())
			.then(json => {
				if (json.success) {
					this.closeModal(this.$shareUrlform);
					notify("Program has been sent successfully.");
				} else {
					const errors = JSON.parse(json.errors);
					Object.keys(errors).map(name => {
						this.$shareUrlform.querySelector(
							`[name="${name}"] + div`
						).innerHTML = errors[name].map(fieldError => {
							return `<p>${fieldError["message"]}</p>`;
						});
					});
				}
			});
	}

	updateShareUrl($input) {
		if (this.$shareUrlInput) {
			const d = this.getPages();
			this.$shareUrlInput.value = `${BASE_URL}?saved=${d.favourites.join("|")}`;
		}
	}

	updateFavouritesHTML() {
		let data = this.getPages();
		this.eventCards.forEach($c => {
			if (data.favourites.indexOf($c.dataset.event) !== -1) {
				$c.classList.add("is-active");
			} else {
				$c.classList.remove("is-active");
			}
		});
	}

	filterFavouritesHTML(data) {
		this.eventCards.forEach($c => {
			if (data.favourites.indexOf($c.dataset.event) === -1) {
				$c.classList.toggle("is-hidden");
			}
		});
	}

	toggle(id) {
		this.exists(id) ? this.remove(id) : this.add(id);

		this.updateFavouritesHTML();
	}

	remove(existingId) {
		const storeData = this.getPages();
		storeData.favourites = storeData.favourites.filter(id => {
			return id !== existingId;
		});
		this.updateStore(storeData);

		u.gEvent("Program", "remove_favourite", existingId);
	}

	add(newId) {
		const storeData = this.getPages();

		storeData.favourites.push(newId);
		this.updateStore(storeData);

		u.gEvent("Program", "add_favourite", newId);
	}

	exists(newId) {
		let data = this.getPages();
		return data.favourites.find(existingId => {
			return existingId === newId;
		});
	}

	updateStore(data) {
		localStorage.setItem(UNCO_PAGESTORE_KEY, JSON.stringify(data));
		this.updateShareUrl();
	}

	getStore() {
		const store = localStorage.getItem(UNCO_PAGESTORE_KEY);
		return store ? JSON.parse(store) : { favourites: [] };
	}

	getPages() {
		if (!window.localStorage) {
			return false;
		}
		return this.getStore();
	}
}
