function choiceOther() {
	const $fieldContainers = document.querySelectorAll("[data-choice-other]");

	$fieldContainers.forEach($container => {
		let $fields = $container.querySelectorAll(
			"[data-choice-other-field] input"
		);
		let $otherTextField = $container.querySelector(
			"[data-choice-other-target]"
		);

		// On $otherTextField input/focus, make $trigger selected
		let onUpdate = e => {
			$fields.forEach($f => {
				$f.checked = false;
			});
		};
		$otherTextField.addEventListener("focus", onUpdate);
		$otherTextField.addEventListener("change", onUpdate);
		$otherTextField.addEventListener("keyup", onUpdate);

		// On $fields selected, clear $otherTextField
		$fields.forEach($f => {
			$f.addEventListener("change", e => {
				$otherTextField.value = "";
			});
		});
	});
}

export default choiceOther;
