export default function initDismissables() {
	if (!document.querySelector("[data-dismissable]")) {
		return;
	}
	new Dismissable();
}

const UNCO_DISMISSABLE_KEY = "UNCODismissables";

class Dismissable {
	constructor() {
		this.$dismissables = document.querySelectorAll("[data-dismissable]");

		this.$dismissables.forEach($item => {
			const $trigger = $item.querySelector("[data-dismissable-trigger]");
			$trigger.addEventListener("click", e => {
				this.addDismissed($item.dataset.dismissable);
				this.updateDismissablesHTML();
				e.preventDefault();
			});
		});

		this.updateDismissablesHTML();
	}

	updateDismissablesHTML() {
		const dismissedItems = this.getStore();
		this.$dismissables.forEach($item => {
			if (dismissedItems.indexOf($item.dataset.dismissable) === -1) {
				$item.classList.remove("is-hidden");
			} else {
				$item.classList.add("is-hidden");
			}
		});
	}

	addDismissed(id) {
		let dismissedItems = this.getStore();
		dismissedItems.push(id);
		localStorage.setItem(UNCO_DISMISSABLE_KEY, JSON.stringify(dismissedItems));
	}

	getStore() {
		const store = localStorage.getItem(UNCO_DISMISSABLE_KEY);
		return store ? JSON.parse(store) : [];
	}
}
