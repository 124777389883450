export default function initTito() {
	const $container = document.querySelector("#l-tito-widget-container");

	if (!$container) {
		return;
	}

	if (!window.tito) {
		// Scripts inserted by .innerHTML (Barba.js page transitions) arent parsed, so we need to inject the script ourselves. -JS
		injectTitoScript($container);

		// prettier-ignore
		window.tito = window.tito || function() { (tito.q = tito.q || []).push(arguments); };

		// Protip: This event can be called more than once.
		tito("on:widget:loaded", _ => {
			let ticketEls = document.querySelectorAll(".tito-release");
			if (ticketEls.length > 0) {
				ticketEls = Array.from(ticketEls);
				// For the same reasons as comment on line #8, we need to store JSON as a data attribute -JS
				const data = JSON.parse($container.dataset.titoIntegrationData);

				data.forEach(d => {
					wrapTickets(ticketEls.splice(0, d.count), d);
				});
			}
		});
	}

	const titoEventId = $container.dataset.titoEventId;

	tito("widget.mount", {
		el: "#l-tito-widget",
		event: titoEventId
	});
}

function injectTitoScript($container) {
	const script = document.createElement("script");
	script.type = "text/javascript";
	script.async = true;
	script.src = "https://js.tito.io/v2/with/inline";
	$container.appendChild(script);
}

function wrapTickets(els, event) {
	let wrapper = document.createElement("details");
	wrapper.setAttribute("class", "tito-group");

	let summary = document.createElement("summary");
	summary.setAttribute("class", "tito-group--summary");

	let summaryInner = document.createElement("div");
	summaryInner.setAttribute("class", "tito-group--summary-inner");
	summaryInner.innerHTML =
		"<svg viewBox='0 0 13.59 8.21'><use xlink:href='#icon-arrow-down'></use></svg>";
	summary.appendChild(summaryInner);

	let title = document.createElement("span");
	title.innerText = event.title;
	title.setAttribute("class", "tito-group--summary-title");
	summaryInner.appendChild(title);

	if (event.image) {
		let img = document.createElement("img");
		img.setAttribute("class", "tito-group--summary-img");
		img.src = event.image;
		summaryInner.appendChild(img);
	}

	wrapper.appendChild(summary);

	els[0].parentNode.insertBefore(wrapper, els[0]);

	if (!event.hide_events) {
		// Move all the ticket elemnts from where Tito put them, into our new wrapper
		els.forEach(el => wrapper.appendChild(el));
	} else {
		let message = document.createElement("p");
		message.innerText = event.hidden_message;
		wrapper.appendChild(message);

		// Remove the ticket elements from the page so they don't get listed outside the wrapper we've made
		els.forEach(el => el.remove());
	}
}
