function menuActions() {
	const $navTrigger = document.querySelector("[data-navigation-trigger]");
	const $navTarget = document.querySelector("[data-navigation-target]");
	const $mobileMenus = document.querySelectorAll("[data-mobile-submenu]");

	if ($navTrigger && $navTarget) {
		const $navLinks = $navTarget.querySelectorAll(
			":scope a:not([data-mobile-submenu-back])"
		);
		$navTrigger.addEventListener("click", e => {
			$navTrigger.classList.toggle("is-active");
			$navTarget.classList.toggle("is-active");
			document.body.classList.toggle("nav-is-active");

			$mobileMenus.forEach($submenu => {
				$submenu
					.querySelector("[data-mobile-submenu-list]")
					.classList.remove("is-active");
			});

			e.preventDefault();
		});

		$navLinks.forEach($l => {
			$l.addEventListener("click", e => {
				$navTrigger.classList.toggle("is-active");
				$navTarget.classList.toggle("is-active");
				document.body.classList.remove("nav-is-active");

				$mobileMenus.forEach($submenu => {
					$submenu
						.querySelector("[data-mobile-submenu-back]")
						.classList.remove("is-active");
				});
			});
		});
	}

	if ($mobileMenus) {
		$mobileMenus.forEach($submenu => {
			const $trigger = $submenu.querySelector("[data-mobile-submenu-opener]");
			const $target = $submenu.querySelector("[data-mobile-submenu-list]");
			$trigger.addEventListener("click", e => {
				$target.classList.add("is-active");
				e.preventDefault();
			});
			$submenu
				.querySelector("[data-mobile-submenu-back]")
				.addEventListener("click", e => {
					$target.classList.remove("is-active");
					e.preventDefault();
				});
		});
	}

	document.querySelectorAll("[data-mobile-submenu]").forEach($link => {
		$link.addEventListener("mouseenter", e => {
			$navTarget.classList.add("has-open-submenu");
			e.preventDefault();
		});
		$link.addEventListener("mouseleave", e => {
			$navTarget.classList.remove("has-open-submenu");
			e.preventDefault();
		});
	});

	document
		.querySelectorAll("[data-submenu-image-trigger]")
		.forEach($trigger => {
			let index = $trigger.dataset["submenuImageTrigger"];
			let triggerImage = document.querySelector(
				`[data-submenu-image-container="${index}"]`
			);
			if (triggerImage) {
				$trigger.addEventListener("mouseenter", e => {
					triggerImage.classList.add("is-active");
				});
				$trigger.addEventListener("mouseleave", e => {
					triggerImage.classList.remove("is-active");
				});
			}
		});

	const $lightModeTriggers = document.querySelectorAll(
		"[data-toggle-light-mode]"
	);
	const lightModeKey = "UNCOLightMode";

	if ($lightModeTriggers.length > 0) {
		$lightModeTriggers.forEach($t => {
			$t.addEventListener("change", e => {
				document.body.classList.toggle("theme-a11y");
				localStorage.setItem(lightModeKey, $t.checked);
			});
		});

		const lightModeState = JSON.parse(localStorage.getItem(lightModeKey));

		if (lightModeState) {
			document.body.classList.add("theme-a11y");
			$lightModeTriggers.forEach($t => ($t.checked = true));
		}
	}
}

export default menuActions;
