import u from "../utils";

import createBarba from "./createBarba.js";
import { smallFold, largeFold } from "./views.js";

function initTransitions(onTransitionFinish, onTransitionFinishAfterTimeout) {
	const Barba = createBarba(onTransitionFinish, onTransitionFinishAfterTimeout);
	smallFold(Barba).init();
	largeFold(Barba).init();
	Barba.Pjax.start();

	Barba.Dispatcher.on("newPageReady", u.sendAnalytics);
}

export default initTransitions;
