import u from "../utils";

function largeFold(Barba) {
	return Barba.BaseView.extend({
		namespace: "largefold",
		onEnter: function() {
			u.removeClass(document.body, "has-minimal-image");
		},
		onEnterCompleted: function() {
			u.removeClass(document.body, "has-minimal-header");
		}
	});
}

function smallFold(Barba) {
	return Barba.BaseView.extend({
		namespace: "smallfold",
		onEnterCompleted: function() {
			u.addClass(document.body, "has-minimal-image");
			u.addClass(document.body, "has-minimal-header");
		}
	});
}

export { largeFold, smallFold };
